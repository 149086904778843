.album span {
  font-size: 18px;
  cursor: pointer;
  border-bottom: 1px dotted #ddd;
  color: #62aadb;
}

.selected {

}
.selected .albums {
  display: none;
}
.selected .photos {
  display: block;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.container {
  position: relative;
  height: 400px;
}

.photo_wrapper {
  position: absolute;
  width: 100%;
  height: 400px;
}

.photo_wrapper[data-new="true"] {
  z-index: 1;
}

.photo {
  /*width: 100%;*/
  height: 400px;
  /* background: #fff url('assets/ajax-loader.gif') 50% 50% no-repeat; */
  overflow: hidden;
  text-align: center;
}

.canvas {
  height: 400px;
}

[data-loaded="true"] {
  opacity: 1;
}

.photo img {
  max-width: 100%;
}

.loading {
  background-size: fit;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
  opacity: 0;
  transition: opacity 2s;
}

.video[data-visible="true"] {
  opacity: 1;
}

.selectOther {
  font-size: 14px;
  color: #666;
}

.selectOther span {
  display: inline-block;
  margin: 5px 15px;
  cursor: pointer;
}

.selectOther span:first-child {
  float: right;
}

.collapse_after ~ * {
  display: none;
}

.extLink {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.extLink::before {
  content: '🔗';
  position: absolute;
  top: 50px;
  left: 50px;
  font-size: 33px;
  transform: translate(-50% -50%);
  margin: -16px 0 0 -16px;
  background: rgba(255, 255, 255, .3);
  padding: 9px 12px 5px;
  border-radius: 40px;
}

.transparent {
  opacity: 0;
  transition: opacity 3s;
}

.collapseWrap {
  float: right;
}
