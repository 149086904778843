.loading {
  padding: 45px 0 10px;
  /* background: transparent url('/public/ajax-loader.gif') 50% 10px no-repeat; */
  text-align: center;
}

.loading[data-no-text="true"] {
  text-indent: -9999px;
  font-size: 0;
}
