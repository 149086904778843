.calendarItem,
.item {
  margin-bottom: 12px;
  padding: 15px 20px;
  font-size: 20px;
  background: rgba(80, 150, 255, 0.05);
  border-radius: 8px;
}


.calendarItem {
  cursor: pointer;
}

.item_whole_day {
  background: rgba(112, 199, 32, 0.05);
}

.item_done {
  opacity: 0.5;
}

.hide {
  display: none;
}

.selectOther {
  text-align: right;
  color: #666;
  font-size: 14px;
}

.collapseWrap {
  float: right;
}

.subtitle {
  color: #666;
}
