.container {
  background: transparent;
}

.container h1 {
  margin: 0;
  text-align: center;
  font-size: 3em;
  color: #4e4e4e;
}
.container h1 b {
  font-weight: normal;
  animation: blink-animation 1s steps(2, start) infinite;
  -webkit-animation: blink-animation 1s steps(2, start) infinite;
}

.day {
  text-transform: capitalize;
  margin-right: 0.4em;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
