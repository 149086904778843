.weatherWidget {

}
.icon {
  float: left;
  width: 40px;
  margin-right: 20px;
  background: #d8d8d8;
  border-radius: 50%;
}

.wrapper {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.description {
}

.description span {
  font-size: 30px;
}

div .degrees {
  font-size: 30px;
}
div .degreesUnits {
  font-size: 25px;
}

.weather_name {
  margin-right: 15px;
  text-transform: capitalize;
}


.essential {
  margin: 10px 0 0 60px;
  font-size: 20px;
}

.essential > div {
  margin-bottom: 8px;
}

.essential_feels_like {
  font-size: 24px;
}

.essential_feels_like span {
  font-size: inherit;
}

.essential_temp {
  white-space: nowrap;
  font-size: 30px;
}

.essential_humidity {
  font-size: 24px;
}

.configButton {
  float: right;
}
